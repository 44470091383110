/* Mengimpor font dari Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&family=Inter:wght@300;400;500;600;700&display=swap");
/* Variabel Global */
:root {
  --font-primary: "Poppins, Arial, sans-serif";
  --font-secondary: "Inter, sans-serif";
  --color-primary: black;
  --color-transparent: transparent;
}

/* Reset Global Typography */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  color: black;
  font-family: "Poppins, Arial, sans-serif";
}

/* Pengaturan Gambar */
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Tombol dengan teks normal */
button {
  text-transform: none !important;
}

/* Kelas Khusus untuk Warna Teks */
.color_btn {
  color: var(--color-transparent) !important;
}
/* .color_btn {
  color: transparent !important;
}
button {
  text-transform: none !important;
} */
/* Responsivitas untuk Perangkat Kecil */
@media screen and (max-device-width: 412px) {
  html,
  body {
    min-width: 369px;
    overflow-x: hidden;
    font-family: var(--font-primary);
  }
}

/* Menghilangkan Scrollbar di Webkit (Chrome, Safari) */
.hide_scrollbar::-webkit-scrollbar {
  display: none;
}

/* Menghilangkan Scrollbar di Browser Lain */
.hide_scrollbar {
  -ms-overflow-style: none; /* IE dan Edge */
  overflow: hidden; /* General */
  scrollbar-width: none; /* Firefox */
}

Typography {
  font-family: "Poppins", Arial, sans-serif;
}
